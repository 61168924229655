<template>
  <footer class="Footer__container">
    <div class="Footer__wrapper">
      <div class="Footer__row align-start">
        <div class="Footer__header-wrapper">
          <h2 class="Footer__heading">Still have questions?</h2>
          <p class="Footer__paragraph">
            Reach out to our support team for assistance.
          </p>
          <button class="Footer__button" @click="handleOpenIntercom">
            Contact support <o-icon custom-size="mdi-18px" icon="arrow-right" />
          </button>
        </div>
        <div class="Footer__list-wrapper">
          <ul class="Footer__list">
            <li class="Footer__list-element">
              <NuxtLink to="#home">Home</NuxtLink>
            </li>
            <li class="Footer__list-element">
              <NuxtLink to="#about">About</NuxtLink>
            </li>
            <li class="Footer__list-element">
              <NuxtLink to="#features">Features</NuxtLink>
            </li>
          </ul>
          <ul class="Footer__list">
            <li class="Footer__list-element">
              <NuxtLink to="#rooms">Rooms</NuxtLink>
            </li>
            <li class="Footer__list-element">
              <NuxtLink to="#plans">Plans</NuxtLink>
            </li>
            <li class="Footer__list-element">
              <NuxtLink to="#faq">FAQ's</NuxtLink>
            </li>
          </ul>
        </div>
      </div>
      <div class="Footer__row align-end">
        <div class="Footer__logo-wrapper">
          <NuxtLink to="/#home">
            <LandingIconKingsconferenceLogoWhite />
          </NuxtLink>
          <p class="Footer__paragraph mt-16 mb-0">
            Your Online Conference Hub!
          </p>
        </div>
        <div class="Footer__copyright-wrapper">
          <div><span>KingsSuite ©2024 | All Rights Reserved.</span></div>
          <div class="Footer__links-wrapper">
            <NuxtLink to="/terms-and-conditions">
              Terms and conditions.
            </NuxtLink>
            <NuxtLink to="/privacy-policy"> Privacy policy. </NuxtLink>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script setup lang="ts">
const { $intercom } = useNuxtApp();

const handleOpenIntercom = () => {
  $intercom.boot();
};
</script>
<style scoped lang="scss">
.Footer {
  &__container {
    background: $color-neutral-950;
    border-radius: 20px 20px 0 0;
    margin-top: 80px;
  }
  &__wrapper {
    padding: 80px 16px 24px 16px;
    @include md {
      max-width: 1440px;
      margin: auto;
    }
  }
  &__header-wrapper {
    display: flex;
    flex-direction: column;
    padding-bottom: 80px;
    border-bottom: 1px solid $color-neutral-800;
    @include md {
      border-bottom: none;
    }
  }
  &__heading {
    @include Header-32;
    color: $color-element-light-strong;
  }
  &__paragraph {
    @include Body-16;
    margin-top: 8px;
    margin-bottom: 40px;
    color: $color-element-light-basic;
    &.mt-16 {
      margin-top: 16px;
    }
    &.mb-0 {
      margin-bottom: 0;
    }
  }
  &__button {
    @include button-primary;
    background: $color-element-light-strong;
    color: $color-neutral-950;
    width: fit-content;
    display: inline-flex;
    flex-direction: row;
    column-gap: 8px;
    justify-content: center;
    align-items: center;
    &:hover {
      .o-icon {
        animation: button-arrow-move 0.4s ease-in alternate infinite;
      }
    }
  }
  &__list-wrapper {
    padding: 80px 0;
    @include md {
      padding: 0;
      padding-right: 20px;
      display: flex;
      flex-direction: row;
      column-gap: 220px;
    }
    @include custom-min-width(992px) {
      padding-right: 100px;
    }
  }
  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    margin-bottom: 24px;
  }
  &__list-element {
    @include Label-16;
    color: $color-element-light-strong;
    a {
      color: inherit;
      text-decoration: none;
    }
    @include md {
      width: 50%;
    }
  }
  &__logo-wrapper {
    display: flex;
    flex-direction: column;
    padding-bottom: 80px;
    border-bottom: 1px solid $color-neutral-800;
    @include md {
      border-bottom: none;
      padding-bottom: 0;
    }
  }
  &__copyright-wrapper {
    @include Body-14;
    padding-top: 24px;
    color: $color-element-light-basic;
    @include md {
      display: flex;
      flex-direction: row;
      column-gap: 24px;
      padding-top: 0;
    }
  }
  &__links-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    row-gap: 12px;
    a {
      text-decoration: none;
      color: inherit;
    }
    @include md {
      margin-top: 0;
      display: flex;
      flex-direction: row;
      column-gap: 24px;
    }
  }
  &__row {
    @include md {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      &.align-end {
        align-items: flex-end;
      }
      &.align-start {
        column-gap: 24px;
        align-items: flex-start;
      }
    }
  }
}
</style>
